<template>
  <section class="contato__import">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card title="Importar Contatos">
          <b-row>
            <b-col cols="4">
              <p class="card-description white-mode__black">
                Siga os passos abaixo para realizar a importação:
              </p>
              <ul class="list-arrow">
                <li>
                  <span class="white-mode__black">Baixe o arquivo modelo:</span>
                </li>
                <li>
                  <a :href="arquivoImportacaoOdsUrl">ODS</a>
                  <span class="white-mode__black">|</span>
                  <a :href="arquivoImportacaoXlsxUrl">XLSX</a>
                  <span class="white-mode__black">|</span>
                  <a :href="arquivoImportacaoCsvUrl">CSV</a>
                </li>
                <li>
                  <span class="white-mode__black"
                    >Preencha as colunas correspondentes</span
                  >
                </li>
                <li>
                  <span class="white-mode__black"
                    >Atributos multivalorados como telefones e emails podem ser
                    separados por linhas, virgula ou "/"</span
                  >
                </li>
                <li>
                  <span class="white-mode__black"
                    >Salve o arquivo e envie ao lado</span
                  >
                </li>
              </ul>
            </b-col>
            <b-col cols="8">
              <form>
                <b-form-group label="Projeto" v-if="importLeads">
                  <projeto-select></projeto-select>
                </b-form-group>
                <b-form-group label="Tags" v-if="importLeads">
                  <tag-input v-model="tags"></tag-input>
                </b-form-group>

                <b-form-group
                  label="Envie seu arquivo"
                  label-for="arquivo"
                  description="Tamanho Máximo: 64 MB"
                >
                  <b-form-file
                    @input="uploadCsv()"
                    v-model="arquivo"
                    id="arquivo"
                    :state="Boolean(arquivo)"
                    accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
                    placeholder="Selecione ou arraste um arquivo aqui..."
                    drop-placeholder="Arraste o arquivo aqui..."
                  ></b-form-file>
                </b-form-group>
              </form>
              <div v-if="importing" class="circle-loader"></div>
            </b-col>
          </b-row>
          <b-table
            show-empty
            stacked="md"
            :items="items"
            :fields="fields"
            select-mode="multi"
            selected-variant="dark"
            responsive
            :busy="loadingTable"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>
            <template #cell(erros)="row">
              <template v-if="row.item.erros > 0">
                <b-badge
                  :id="'import-popover-' + row.item.id"
                  href="#"
                  pill
                  variant="danger"
                  >{{ row.item.erros }}</b-badge
                >
                <b-popover
                  :target="'import-popover-' + row.item.id"
                  variant="danger"
                  triggers="hover"
                  container="body"
                >
                  <template #title>Erros</template>
                  <span style="white-space: pre-line"
                    >{{ row.item.erros_descricao }}
                  </span>
                </b-popover>
              </template>
              <template v-else>
                <b-badge pill variant="primary">{{ row.item.erros }}</b-badge>
              </template>
            </template>
            <template #cell(processado)="row">
              {{ row.value == 0 ? "Pendente" : "Finalizado" }}
            </template>
            <template #cell(created_at)="row">
              {{ row.value | formatDateTime }}
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import ProjetoSelect from "@/components/ProjetoSelect.vue";
import TagInput from "@/components/TagInput.vue";
import ImportService from "@/services/import.service";

export default {
  name: "ContatoImport",
  components: {
    ProjetoSelect,
    TagInput,
  },
  data() {
    return {
      arquivoImportacaoOdsUrl:
        process.env.VUE_APP_API_URL +
        "/importacao/piattino_contatos_importacao.ods",
      arquivoImportacaoXlsxUrl:
        process.env.VUE_APP_API_URL +
        "/importacao/piattino_contatos_importacao.xlsx",
      arquivoImportacaoCsvUrl:
        process.env.VUE_APP_API_URL +
        "/importacao/piattino_contatos_importacao.csv",
      fields: [
        {
          label: "Nome",
          key: "arquivo",
        },
        {
          key: "novos",
        },
        {
          key: "atualizados",
        },
        {
          key: "erros",
        },
        {
          label: "Status",
          key: "processado",
        },
        {
          label: "Usuário",
          key: "creator.nome",
        },
        {
          label: "Data",
          key: "created_at",
        },
      ],
      arquivo: null,
      items: [],
      loadingTable: false,
      importing: false,
      tags: [],
    };
  },
  computed: {
    ...mapState(["projetoSelecionado"]),
  },
  props: ["importLeads"],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadingTable = true;
      const promise = ImportService.getAll({ tipo: "contato" });
      return promise
        .then((response) => {
          this.items = response.data || [];
        })
        .then(() => {
          this.loadingTable = false;
        });
    },
    uploadCsv() {
      if (this.arquivo == null) return;

      this.importing = true;
      let formData = new FormData();
      formData.append("arquivo", this.arquivo);
      formData.append("tipo", "contato");
      formData.append("importLeads", +this.importLeads);
      if (this.projetoSelecionado) {
        formData.append("projeto_id", this.projetoSelecionado.id);
      }
      if (this.tags) {
        this.tags.forEach((elem) => {
          formData.append("tags[]", elem);
        });
      }

      const promise = ImportService.uploadCsv(formData);

      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
        })
        .catch(() => {})
        .then(() => {
          this.importing = false;
          this.arquivo = null;
          this.loadData();
        });
    },
  },
};
</script>


<style lang="scss" >
.contato__import {
  .form-group label {
    display: flex;
    align-items: center;
  }
}
</style>